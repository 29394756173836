<template>
  <v-card 
    width="100%"
    flat
  >
    <v-row
      no-gutters 
      :style="{height: '12%'}"
    >
      <v-card-title
        :style="{borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh'}"
      >
        {{ title }}
        <v-tooltip bottom>
          <template #activator="{attrs, on}">
            <v-icon
              class="ml-4 mb-1"
              v-bind="attrs"
              v-on="on"
            >
              info
            </v-icon>
          </template>
          <span>
            1. Estes últimos códigos são todos incrementados por empresa e não por loja. <br>
            2. Os códigos com dígito verificador no fim são incrementados antes do dígito e a cada incremento é calculado um novo dígito verificador.<br>
            Ex.: 1-9, 2-7, 3-5, 4-3 e assim por diante.
          </span>
        </v-tooltip>
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{height: '88%'}"
      class="pt-2"
    >
      <v-col>
        <v-row
          no-gutters
        >
          <v-col
            sm="8"
            md="8"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Cliente:
          </v-col>
          <v-col
            sm="3"
            md="2"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="pt-2" 
          >
            <v-text-field
              value="29-9"
              disabled
              filled
              outlined
              dense
              flat
              reverse                       
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="8"
            md="8"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Produto:
          </v-col>
          <v-col
            sm="3"
            md="2"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="pt-2"
          >
            <v-text-field
              value="245-3"
              disabled
              filled
              outlined
              dense
              flat   
              reverse        
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="8"
            md="8"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Venda:
          </v-col>
          <v-col
            sm="3"
            md="2"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="pt-2"
          >
            <v-text-field
              value="376"
              disabled
              filled
              outlined
              dense
              flat         
              reverse 
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="8"
            md="8"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Vale funcionário:
          </v-col>
          <v-col
            sm="3"
            md="2"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="pt-2"
          >
            <v-text-field
              value="5-1"
              disabled
              filled
              outlined
              dense
              flat   
              reverse      
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="8"
            md="8"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Transferência:
          </v-col>
          <v-col
            sm="3"
            md="2"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="pt-2"
          >
            <v-text-field
              value="33"
              disabled
              filled
              outlined
              dense
              flat  
              reverse
            />
          </v-col>
        </v-row>
        <v-divider class="mb-5" />
        <v-row no-gutters>
          <v-col
            sm="8"
            md="8"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Contrato crediário:
          </v-col>
          <v-col
            sm="3"
            md="2"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="pt-2"
          >
            <v-text-field
              value="32-9"
              disabled
              filled
              outlined
              dense
              flat   
              reverse       
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="8"
            md="8"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Prestação contrato crediário:
          </v-col>
          <v-col
            sm="3"
            md="2"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="pt-2"
          >
            <v-text-field
              value="80-9"
              disabled
              filled
              outlined
              dense
              flat 
              reverse           
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props:{
    title:{
      type: String,
      required: true,
      default: '',
    },
  },
  data(){
    return {

    }
  },
  watch:{
    
  },
  mounted(){

  },
  methods: {
    
  },
}
</script>